import { Row, Col } from "reactstrap";
import { FaLongArrowAltDown } from "react-icons/fa";

const Education = () => {
  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h6 className="work-title">Master of Applied Computing</h6>
          <h6 className="work-company">Lincoln University, New Zealand</h6>
          <h6 className="work-duration">Expected Finish - Sept 2023</h6>
        </Col>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h6 className="work-title">
            BSc. (Special) Degree in Computing and Information Systems
          </h6>
          <h6 className="work-company">Sabaragamuwa University of Sri Lanka</h6>
          <h6 className="work-duration">2010-2014</h6>
        </Col>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h6 className="work-title">Certification, UI/ UX designer</h6>
          <h6 className="work-company">Career Foundary</h6>
          <h6 className="work-duration">2016</h6>
        </Col>
      </Row>
    </div>
  );
};

export default Education;

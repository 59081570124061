import { Row, Col } from "reactstrap";
import { FaLongArrowAltDown } from "react-icons/fa";

const Work = () => {
  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="3"></Col>
        <Col xs="12" sm="12" md="3" className="my-3">
          <h5 className="work-header">Work Experience</h5>
          <br />
          <h6 className="work-title">Full Stack Software Developer</h6>
          <h6 className="work-company">QCONZ, Hamilton, New Zealand</h6>
          <h6 className="work-duration">2018 February - Present</h6>
          <br />
          <h6 className="work-title">Software Developer</h6>
          <h6 className="work-company">MVP Studio, Wellington, New Zealand</h6>
          <h6 className="work-duration">2017 July - 2018 February</h6>
          <br />
          <h6 className="work-title">Software Developer</h6>
          <h6 className="work-company">Moresand Limited, Sri Lanka</h6>
          <h6 className="work-duration">2014 November - 2015 December</h6>
          <br />
          <h6 className="work-title">Junior Software Developer</h6>
          <h6 className="work-company">GUI Solutions, Sri Lanka</h6>
          <h6 className="work-duration">2013 August - 2014 October</h6>
        </Col>
        <Col xs="12" sm="12" md="3" className="my-3">
          <h5 className="work-header">UI/ UX</h5>
          <br />
          <a
            className="work-ux"
            href="https://www.behance.net/gallery/47117067/Tazt-Online-Food-Ordering-App/modules/281158171"
            target="_blank"
          >
            Tazt
          </a>
          <h6 className="work-duration">Food Ordering App</h6>
          <br />
          <a
            className="work-ux"
            href="https://www.behance.net/gallery/46992059/PARKiN/modules/280450863"
            target="_blank"
          >
            ParkIn
          </a>
          <h6 className="work-duration">Parking App</h6>
          <br />
          <a
            className="work-ux"
            href="https://www.behance.net/gallery/50570029/Brand-Guide-Network-of-Skilled-Migrants/modules/300216831"
            target="_blank"
          >
            NSMM Manawatu
          </a>
          <h6 className="work-duration">Branding</h6>
        </Col>
        <Col xs="12" sm="12" md="3"></Col>
      </Row>
    </div>
  );
};

export default Work;

import { FaLongArrowAltDown } from "react-icons/fa";

const About = () => {
  return (
    <div className="px-5 py-5 my-5 text-center">
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4 about-me">
          I was born and raised in Sri Lanka, currently living in New Zealand. I've been working, for several companies developing enterprise applications for
          food, travel and retail industries. </p><p>I have a Bachelor's degree in Computing and Information Systems and currently pursuing a Master's degree in Applied Computing.

        </p>
      </div>
    </div>
  );
};

export default About;

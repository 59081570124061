import logo from "./logo.svg";
import "./App.css";
import { FaLongArrowAltDown } from "react-icons/fa";
import About from "./About";
import Work from "./Work";
import Education from "./Education";
import Tech from "./Tech";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <div className="fill-height-or-more">
        <div className="landing">
          <div className="fullName">
            <span className="firstName">Sudam</span>{" "}
            <span className="lastName">Bandara</span>
          </div>
          <div className="title">
            <span>Full Stack Software Developer, UI/UX Enthusiast</span>
          </div>
          <div className="intro">
            <p>
              Full-stack software developer centred on Microsoft's .NET
              Framework. Often work with C#, ASP.NET/MVC, SQL Server & EF6,
              HTML5, CSS, JavaScript, jQuery, React JS etc.
            </p>
            <p>Love to create solutions keeping user experience in mind.</p>
          </div>
          <a href="#about" className="arrow-down home-page">
            <FaLongArrowAltDown />
          </a>
        </div>
      </div>
      <div className="fill-height-or-more">
        <div className="about" id="about">
          <About />
        </div>
        <a href="#work" className="arrow-down-i other-page">
          <FaLongArrowAltDown />
        </a>
      </div>
      <div className="fill-height-or-more">
        <div className="work" id="work">
          <Work />
        </div>
        <a href="#education" className="arrow-down other-page">
          <FaLongArrowAltDown />
        </a>
      </div>
      <div className="fill-height-or-more">
        <div className="education" id="education">
          <Education />
        </div>
        <a href="#tech" className="arrow-down-i other-page">
          <FaLongArrowAltDown />
        </a>
      </div>
      <div className="fill-height-or-more">
        <div className="tech" id="tech">
          <Tech />
        </div>
        <a href="#footer" className="arrow-down other-page">
          <FaLongArrowAltDown />
        </a>
      </div>
      <div className="fill-height-or-more">
        <div className="footer" id="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

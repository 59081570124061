import { Row, Col } from "reactstrap";
import { FaLongArrowAltDown } from "react-icons/fa";

const Tech = () => {
  return (
    <div>
      <Row>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h5 className="work-header">
            Skilled Languages, Libraries & Frameworks
          </h5>
          <br />
          <h6 className="work-title">C#</h6>
          <h6 className="work-title">ASP.NET (Core, MVC5, Web Forms)</h6>
          <h6 className="work-title">REST API</h6>
          <h6 className="work-title">Entity Framework</h6>
          <h6 className="work-title">SQL</h6>
          <h6 className="work-title">JavaScript/ jQuery/ React JS</h6>
          <h6 className="work-title">Python</h6>
          <h6 className="work-title">HTML</h6>
          <h6 className="work-title">CSS</h6>
        </Col>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h5 className="work-header">Skilled Tools</h5>
          <br />
          <h6 className="work-title">Visual Studio</h6>
          <h6 className="work-title">Visual Studio Code</h6>
          <h6 className="work-title">SQL Server Management Studio</h6>
          <h6 className="work-title">Git (Azure DevOps)</h6>
          <h6 className="work-title">CI/ CD</h6>
        </Col>
        <Col xs="12" sm="12" md="4" className="my-3">
          <h5 className="work-header">UI/ UX</h5>
          <br />
          <h6 className="work-title">Protoryping & Wireframing</h6>
          <h6 className="work-title">Sketch App, Figma</h6>
        </Col>
      </Row>
    </div>
  );
};

export default Tech;

import { Row, Col } from "reactstrap";
import {
  FaLongArrowAltDown,
  Fa500Px,
  FaGithub,
  FaDribbble,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Education = () => {
  return (
    <div>
      <div className="px-5 py-5 my-5 text-center">
        <div className="col-lg-6 mx-auto">
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a
              href="https://twitter.com/sudambandara"
              className="social"
              target="_blank"
            >
              <FaTwitter />
            </a>
            <a
              href="https://dribbble.com/sudam"
              className="social"
              target="_blank"
            >
              <FaDribbble />
            </a>
            <a
              href="https://github.com/sudam"
              className="social"
              target="_blank"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/sudambandara/"
              className="social"
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://www.instagram.com/sudambandara/"
              className="social"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://500px.com/p/sudambandara"
              className="social"
              target="_blank"
            >
              <Fa500Px />
            </a>
          </div>
          <br />
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center footer">
            &copy; Sudam Bandara // 2022
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
